import React, { useState } from 'react';
import { useAuth } from '../hooks/AuthProvider';
import { useLocation } from 'react-router-dom';

const LoginContainer = () => {
    const [data, setData] = useState({ email: '', password: '' });
    const auth = useAuth();
    const [errors, setErrors] = useState({});
    const [errorsMessages, setErrorsMessages] = useState({});
    const location = useLocation();

    function validateInputs(inputs) {
        let errors = {};
        if (!inputs.email.trim()) {
            errors.email = 'Indica un correo eléctronico.';
        } else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
            errors.email = 'Email no válido.';
        }
        if (!inputs.password.trim()) {
            errors.password = 'Indica una contraseña.';
        }
        return errors;
    }

    const handleSubmit = async (event) => {
        setErrorsMessages({});
        setErrors({});
        event.preventDefault();
        const validationErrors = validateInputs(data);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            auth.loginAction(data, location).then(result => {
                if (result && !result.success) {
                    setErrorsMessages(result.message); 
                }
            });
        }
    };

    return (
        <div className='h-[calc(100vh-100px)] flex justify-center items-center  flex-col'>
            <h1 className='text-3xl font-bold text-primary'>inicio de sesión</h1>
            <form onSubmit={handleSubmit} className='mt-12'>
                <div className='flex flex-col items-center justify-center gap-4'>
                    <input
                        type="text"
                        className='w-[300px]  rounded-full px-4 py-4 transition-all ease-in-out duration-150'
                        value={data.email}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                        placeholder="email" />
                    <input
                        type="password"
                        className='w-[300px]  rounded-full px-4 py-4'
                        value={data.password}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                        placeholder="contraseña" />
                    <button
                        type="submit"
                        className='flex items-center justify-center w-full gap-2 px-24 py-4 text-white transition-all duration-150 ease-in-out rounded-full bg-primary button stroke-white hover:bg-opacity-90'>
                        iniciar sesión
                    </button>
                    <div className='flex flex-col justify-center text-center'>
                        <div className='flex gap-2 mx-auto'>
                            <span>¿no tienes cuenta?</span>
                            <a href="/register" className="underline cursor-pointer text-primary">registrate aquí</a>
                        </div>
                        <div className='flex gap-2 mx-auto'>
                            <a href="/forgot-password" className="underline cursor-pointer text-primary">¿olvidaste la contraseña?</a>
                        </div>
                    </div>
                    <div className='flex flex-col w-full text-center'>
                        {errors.email && <p className="w-full text-xs text-center text-red-500">{errors.email}</p>}
                        {errors.password && <p className="w-full text-xs text-center text-red-500">{errors.password}</p>}
                        {errorsMessages.length > 0 && <p className="w-full text-xs text-center text-red-500">{errorsMessages}</p>}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LoginContainer;

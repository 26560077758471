import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const PostForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    cifNif: "",
    needs: "",
    city: "",
    date: null,
    termsAccepted: false,
    commercialTermsAccepted: false,
  });

  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, lastName, phoneNumber, email, cifNif, city, needs, date, termsAccepted } =
      formData;

    if (!termsAccepted) {
      setModalMessage(
        "Debes aceptar el aviso legal y privacidad de uso de tus datos personales."
      );
      setIsModalOpen(true);
      return;
    }

    const message = `
      Teléfono: ${phoneNumber}
      CIF/NIF: ${cifNif}
      Ciudad: ${city}
      Necesidades: ${needs}
      Fecha seleccionada: ${date ? new Date(date).toLocaleDateString() : "No especificada"}
    `;

    const payload = {
      name,
      surname: lastName,
      email,
      message,
      to: "pablomartin@vectoriam.com", 
      type: "cta",
    };

    try {
      const response = await fetch(`${API_BASE_URL}/send-notification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const error = await response.json();
        console.error("Error al enviar la notificación:", error);
        setModalMessage("Hubo un problema al enviar el formulario. Inténtalo de nuevo.");
      } else {
        setModalMessage("Formulario enviado con éxito.");
        setFormData({
          name: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          cifNif: "",
          needs: "",
          city: "",
          date: null,
          termsAccepted: false,
          commercialTermsAccepted: false,
        });
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setModalMessage("Hubo un problema al enviar el formulario. Inténtalo de nuevo.");
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mt-3 px-4 py-8 bg-white rounded-md shadow-md border-primary">
      <h2 className="text-2xl font-bold text-center mb-6 text-primary">
        ¿Qué quieres alquilar? ¡Pregúntanos sin compromiso!
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Apellidos"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Número de Teléfono"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="cifNif"
              id="cifNif"
              value={formData.cifNif}
              onChange={handleChange}
              placeholder="CIF/NIF"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="city"
              id="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Ciudad"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <input
              name="needs"
              id="needs"
              value={formData.needs}
              onChange={handleChange}
              placeholder="Escribe lo que necesitas..."
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              required
            />
          </div>
          <div>
            <Flatpickr
              id="date"
              className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-4 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
              value={formData.date}
              onChange={handleDateChange}
              options={{ minDate: "today" }}
              placeholder="Selecciona una fecha"
            />
          </div>
        </div>
        <div className="flex items-start">
          <input
            type="checkbox"
            name="termsAccepted"
            id="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
            className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
            required
          />
          <label htmlFor="termsAccepted" className="ml-2 block text-sm text-gray-700">
            He leído y acepto el aviso legal y privacidad de uso de mis datos personales
          </label>
        </div>
        <div className="flex items-start">
          <input
            type="checkbox"
            name="commercialTermsAccepted"
            id="commercialTermsAccepted"
            checked={formData.commercialTermsAccepted}
            onChange={handleChange}
            className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
          />
          <label htmlFor="commercialTermsAccepted" className="ml-2 block text-sm text-gray-700">
            He leído y acepto las condiciones legales de los avisos comerciales
          </label>
        </div>
        <button
          type="submit"
          className="w-[80vw] md:w-80 mt-2 m-auto bg-primary button flex items-center justify-center text-white rounded-full px-2 py-6 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150"
        >
          Enviar
        </button>
      </form>

      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full"
            onClick={(e) => e.stopPropagation()} 
          >
            <h3 className="text-xl font-semibold mb-4 text-primary">Gracias</h3>
            <p className="text-gray-700">{modalMessage}</p>
            <button
              className="mt-4 w-full bg-primary text-white py-2 px-4 rounded-lg"
              onClick={closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostForm;
